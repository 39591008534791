// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore} from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  OAuthProvider
} from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// For Internal
// const firebaseConfig = {
//   apiKey: "AIzaSyBdAxiupPJMgYLPQJmW6xPRz8V5ot-ofUY",
//   authDomain: "vekta-functions-38e1e.firebaseapp.com",
//   projectId: "vekta-functions-38e1e",
//   storageBucket: "vekta-functions-38e1e.appspot.com",
//   messagingSenderId: "962421395156",
//   appId: "1:962421395156:web:00f016b9ad0982d4343541",
//   measurementId: "G-QPS7ZJC04N"
// };

// For NKT
const firebaseConfig = {
  apiKey: "AIzaSyBdAxiupPJMgYLPQJmW6xPRz8V5ot-ofUY",
  authDomain: "vekta-functions-38e1e.firebaseapp.com",
  projectId: "vekta-functions-38e1e",
  storageBucket: "vekta-functions-38e1e.appspot.com",
  messagingSenderId: "962421395156",
  appId: "1:962421395156:web:2265f86d5150dc14343541",
  measurementId: "G-Z0Q9CEDL3W"
};

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    // const userSaves = doc(db, "userSaves", user.uid)
    // const existsSaves = await getDoc(userSaves)

    // if (!existsSaves.exists()) {
    //   await setDoc(doc(db, "userSaves", user.uid), {})
    // }

    // const userAccount = doc(db, "userAccounts", user.uid)
    // const existsAccount = await getDoc(userAccount)

    // if (!existsAccount.exists()) {
    //   await setDoc(doc(db, "userAccounts", user.uid), {
    //     firstname: "",
    //     lastname: "",
    //     email: user.email,
    //     compnay_university: "",
    //     user_group: "",
    //     owned_apps: [],
    //     subscription: "",
    //     admin_level: 32,
    //     sub_users: [],
    //   })
    // }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const microsoftProvider = new OAuthProvider('microsoft.com');
export const signInWithMicrosoft = async () => {
  try {
    const res = await signInWithPopup(auth, microsoftProvider);
    const user = res.user;

    // const userSaves = doc(db, "userSaves", user.uid)
    // const existsSaves = await getDoc(userSaves)

    // if (!existsSaves.exists()) {
    //   await setDoc(doc(db, "userSaves", user.uid), {})
    // }

    // const userAccount = doc(db, "userAccounts", user.uid)
    // const existsAccount = await getDoc(userAccount)

    // if (!existsAccount.exists()) {
    //   await setDoc(doc(db, "userAccounts", user.uid), {
    //     firstname: "",
    //     lastname: "",
    //     email: user.email,
    //     compnay_university: "",
    //     user_group: "",
    //     owned_apps: [],
    //     subscription: "",
    //     admin_level: 32,
    //     sub_users: [],
    //   })
    // }
    
    
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    // const userSaves = doc(db, "userSaves", user.uid)
    // const existsSaves = await getDoc(userSaves)

    // if (!existsSaves.exists()) {
      // await setDoc(doc(db, "userSaves", user.uid), {})
    // }

    // const userAccount = doc(db, "userAccounts", user.uid)
    // const existsAccount = await getDoc(userAccount)

    // if (!existsAccount.exists()) {
      // await setDoc(doc(db, "userAccounts", user.uid), {
      //   firstname: "",
      //   lastname: "",
      //   email: user.email,
      //   compnay_university: "",
      //   user_group: "",
      //   owned_tools: [],
      //   subscription: "",
      //   admin_level: 32,
      //   sub_users: [],
      // })
    // }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// if(window.location.hostname === "localhost") 

export const proxy_url = "https://europe-west1-vekta-functions-380610.cloudfunctions.net/cable_api_bp_proxy_2_2_1"

// export const proxy_url = "http://localhost:8084/"

// if(window.location.hostname === "localhost") 
//   connectFirestoreEmulator(db, "localhost", 8080);
  
// }else{

// }
//   // connectFirestoreEmulator(db, "localhost", 8282);
//   connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  
// }else{

// }
//   // connectFirestoreEmulator(db, "localhost", 8282);
//   connectFunctionsEmulator(functions, "127.0.0.1", 5001)
// }
