import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledIconButton } from "./styles/styles";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";
import React from "react";
import { Divider } from "@mui/material";
import styled from "styled-components";
// import { useRef } from "react";

const Container = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const CopyButton = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 8px;
  background-color: ${({ clicked }) => (clicked ? "#007592" : "#007592")};
  color: white;
  border: none;
  border-radius: 4px;
`;

export class BpGraph {
  constructor() {
    // const cost_breakdown = useRef();
    this.profit_margin_explain = React.createRef();

    this.bp_graph = {
      "metadata:name": {
        requires: {},
        default: "Default Cable Name",
      },
      "design:cable_type": {
        requires: {},
        default: "three_core_armoured",
        options: [
          "single_core",
          "single_core_armoured",
          "three_core",
          "three_core_armoured",
          "dc_cable",
          "dc_cable_armoured",
          "dc_cable_with_metallic_screen",
          "dc_cable_armoured_with_metallic_screen"
        ]
      },
      "metadata:system_voltage": {
        requires: {},
        default: 66,
        options: [
          11, 22, 33, 45, 66, 70, 110, 132, 150, 220, 275, 320, 330, 400, 500,
          765, 800, 1100, 1150,
        ],
        unit: "kV",
      },
      "metadata:nominal_voltage": {
        requires: {},
        default: 66,
        options: [
          10, 20, 30, 45, 66, 70, 110, 132, 150, 220, 275, 320, 330, 400, 500,
          750, 800, 1100,
        ],
        unit: "kV",
      },
      "metadata:maximum_voltage": {
        requires: {},
        default: 72.5,
        options: [
          12, 24, 36, 52, 72.5, 84, 123, 145, 170, 245, 300, 320, 362, 420, 550,
          800, 1100, 1200,
        ],
        unit: "kV",
      },
      "conductor:material": {
        requires: {},
        default: "copper",
        options: ["copper", "aluminium"],
      },
      "metadata:conductor_size": {
        requires: {},
        default: 630,
        options: [
          70, 95, 120, 150, 185, 240, 300, 400, 500, 630, 800, 1000, 1200, 1400,
          1600, 1800, 2000, 2500,
        ],
        unit: "sqmm",
      },
      "conductor:type": {
        requires: {},
        default: "round_stranded",
        options: [
          "round_stranded",
          "round_stranded_compacted",
          "round_solid",
          "round_milliken",
          "sector_shaped",
        ],
      },
      "conductor:compacting_calculation_option": {
        requires: {
          "conductor:type": ["round_stranded_compacted", "round_milliken"],
        },
        default: "automatic",
        options: ["automatic", "user_specified"],
      },
      "conductor:diameter_compacting_ratio": {
        requires: {
          "conductor:type": ["round_stranded_compacted", "round_milliken"],
          "conductor:compacting_calculation_option": ["user_specified"],
        },
        default: 95,
        unit: "%",
        options: [93, 94, 95, 96, 97, 98, 99, 100],
      },
      "conductor:diameter": {
        requires: {
          // "conductor:type": ["round_stranded", "round_solid"],
        },
        default: 29.8,
        unit: "mm",
        min: 1,
        max: 120,
        type: "number"
      },
      "conductor:area_calculation_method": {
        requires: {
          "conductor:type": [
            "round_stranded",
            "round_stranded_compacted",
            "round_milliken",
          ],
        },
        default: "use_only_diameter",
        options: [
          "use_only_diameter",
          "use_number_of_strands",
          "use_strand_diameter",
          "use_number_of_strands_and_diameter",
        ],
      },
      "conductor:strand_diameter": {
        requires: {
          "conductor:type": [
            "round_stranded",
            "round_stranded_compacted",
            "round_milliken",
          ],
          "conductor:area_calculation_method": [
            "use_strand_diameter",
            "use_number_of_strands_and_diameter",
          ],
        },
        default: 2,
        unit: "mm",
        min: 0,
        max: 10,
        type: "number"
      },
      "conductor:number_of_strands": {
        requires: {
          "conductor:type": [
            "round_stranded",
            "round_stranded_compacted",
            "round_milliken",
          ],
          "conductor:area_calculation_method": [
            "use_number_of_strands",
            "use_number_of_strands_and_diameter",
          ],
        },
        default: 52,
        unit: "qty",
        min: 1,
        max: 5000,
        type: "number"
      },
      "design:shielding": {
        requires: {},
        default: "with_shielding",
        options: ["with_shielding", "without_shielding"],
      },
      "conductor_shield:material": {
        requires: {
          "design:shielding": ["with_shielding"],
        },
        default: "xlpe_semi_conductive",
        options: [
          "xlpe_semi_conductive",
          "pe_semi_conductive",
          "epr_semi_conductive",
        ],
      },
      "conductor_shield:thickness": {
        requires: {
          "design:shielding": ["with_shielding"],
        },
        default: 0.4,
        unit: "mm",
        min: 0,
        max: 10,
        type: "number"
      },
      "insulation:material": {
        requires: {},
        default: "xlpe",
        options: ["epr", "pvc", "hdpe", "ldpe", "xlpe_filled", "xlpe_unfilled"],
      },
      "insulation:thickness": {
        requires: {},
        default: 9,
        min: 0,
        max: 50,
        unit: "mm",
        type: "number"
      },
      "insulation_shield:material": {
        requires: {
          "design:shielding": ["with_shielding"],
        },
        default: "xlpe_semi_conductive",
        options: [
          "xlpe_semi_conductive",
          "pe_semi_conductive",
          "epr_semi_conductive",
        ],
      },
      "insulation_shield:thickness": {
        requires: {
          "design:shielding": ["with_shielding"],
        },
        default: 0.6,
        unit: "mm",
        min: 0,
        max: 10,
        type: "number"
      },
      "metallic_screen:material": {
        "requires": {
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        default: "copper",
        options: ["copper", "aluminium", "lead"],
      },
      "metallic_screen:type": {
        "requires": {
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        default: "stranded",
        options: ["stranded", "sheath", "tape"],
      },
      "metallic_screen:calculation_method": {
        "requires": {
          "metallic_screen:type": [
            "stranded"
          ],
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        options: ["calculate_with_area", "calculate_with_number_of_strands"],
        default: "calculate_with_area",
      },
      "metallic_screen:area": {
        "requires": {
          "metallic_screen:type": [
            "stranded",
            "tape"
          ],
          "metallic_screen:calculation_method": [
            "calculate_with_area"
          ],
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        default: 16,
        min: 0,
        max: 1000,
        unit: "sqmm",
        type: "number"
      },
      "metallic_screen:strand_diameter": {
        "requires": {
          "metallic_screen:type": [
            "stranded"
          ],
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        default: 0,
        min: 0,
        max: 10,
        unit: "mm",
        type: "number"
      },
      "metallic_screen:number_of_strands": {
        "requires": {
          "metallic_screen:type": [
            "stranded"
          ],
          "metallic_screen:calculation_method": [
            "calculate_with_number_of_strands"
          ],
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        default: 0,
        unit: "qty",
        min: 0,
        max: 5000,
        type: "number"
      },
      "metallic_screen:thickness": {
        "requires": {
          "metallic_screen:type": [
            "sheath"
          ],
          "design:cable_type": [
            "single_core",
            "single_core_armoured",
            "three_core",
            "three_core_armoured",
            "dc_cable_with_metallic_screen",
            "dc_cable_armoured_with_metallic_screen"
          ]
        },
        default: 0.5,
        unit: "mm",
        min: 0,
        max: 10,
        type: "number"
      },
      "protective_sheath:material": {
        requires: {},
        default: "pe",
        options: [
          "epr",
          "xlpe",
          "pvc",
          "hdpe",
          "ldpe",
          "pe",
          "fibrous_material",
        ],
      },
      "protective_sheath:thickness": {
        requires: {},
        default: 3.5,
        unit: "mm",
        min: 0,
        max: 20,
        type: "number"
      },
      "filler:material": {
        requires: {
          "design:cable_type": ["three_core", "three_core_armoured"],
        },
        default: "pe",
        options: ["pe", "hdpe", "ldpe", "fibrous_material"],
      },
      "filler:shape": {
        requires: {
          "design:cable_type": ["three_core", "three_core_armoured"],
        },
        default: "circle_packed",
        options: ["solid_fit", "hollow_fit", "circle_packed"],
      },
      "armour:material": {
        requires: {
          "design:cable_type": [
            "three_core_armoured", 
            "single_core_armoured",
            "dc_cable_armoured",
            "dc_cable_armoured_with_metallic_screen"
          ],
        },
        default: "stainless_steel",
        options: [
          "stainless_steel",
          "aluminium",
          "plastic",
          "plastic_and_stainless_steel",
        ],
      },
      "armour:bedding_material": {
        requires: {
          "design:cable_type": [
            "three_core_armoured", 
            "single_core_armoured",
            "dc_cable_armoured",
            "dc_cable_armoured_with_metallic_screen"
          ],
        },
        default: "fibrous_material",
        options: ["fibrous_material", "pe", "epr"],
      },
      "armour:strand_diameter": {
        requires: {
          "design:cable_type": [
            "three_core_armoured", 
            "single_core_armoured",
            "dc_cable_armoured",
            "dc_cable_armoured_with_metallic_screen"
          ],
        },
        default: 5,
        unit: "mm",
        min: 0,
        max: 10,
        type: "number"
      },
      "armour:number_of_strands": {
        requires: {
          "design:cable_type": [
            "three_core_armoured", 
            "single_core_armoured",
            "dc_cable_armoured",
            "dc_cable_armoured_with_metallic_screen"
          ],
        },
        default: 0,
        unit: "qty",
        min: 0,
        max: 4000,
        type: "number"
      },
      "armour:number_of_layers": {
        requires: {
          "design:cable_type": [
            "three_core_armoured", 
            "single_core_armoured",
            "dc_cable_armoured",
            "dc_cable_armoured_with_metallic_screen"
          ],
        },
        default: 1,
        unit: "qty",
        min: 0,
        max: 3,
        type: "number"
      },
      "outer_cover:material": {
        requires: {},
        default: "epr",
        options: [
          "epr",
          "xlpe",
          "pvc",
          "hdpe",
          "ldpe",
          "pe",
          "fibrous_material",
        ],
      },
      "outer_cover:thickness": {
        requires: {},
        default: 3.5,
        unit: "mm",
        min: 0,
        max: 20,
        type: "number"
      },
      "settings:financial:currency": {
        requires: {},
        default: "EUR",
        options: ["EUR", "GBP", "USD"], // GBP, USD removed due to forex api currently
      },
      "settings:financial:region": {
        requires: {},
        default: "Europe",
        options: ["Europe"],
      },
      "settings:financial:date": {
        requires: {},
        default: "2024-12",
        type: "date",
      },
      "settings:financial:profit_margin": {
        requires: {},
        default: 1.3,
        options: Array.from({ length: 100 }, (_, index) =>
          (0.1 + index * 0.1).toFixed(1)
        ),
        unit: (
          <>
            <StyledIconButton
              title="Project Margin Explained"
              style={{
                // border: "2px solid red",
                borderRadius: "8px",
                width: "100%",
              }}
              onClick={(e) => this.profit_margin_explain.current.toggle(e)}
            >
              <FontAwesomeIcon color="black" icon={faInfoCircle} />
            </StyledIconButton>
            <OverlayPanel
              ref={this.profit_margin_explain}
              style={{

                padding: "16px",
                fontFamily: "Montserrat",
              }}
              appendTo={document.getElementById("cableModal")}
            >
              <h5>Profit Margin Explained</h5>
              <Divider
                style={{ width: "100%", margin: "8px 0", color: "black" }}
              />
              <p style={{ width: "500px" }}>
                This is a cost adjustment factor designed to accommodate market
                variation and uncertainty. We recommend using the following
                settings initially but it will vary based on your project and
                regional supply costs:
              </p>

              <Container>
                <Table>
                  <tbody>
                    <tr>
                      <Th>Year</Th>
                      <Th>Profit Margin</Th>
                    </tr>
                    <tr>
                      <Td>2017 - 2020</Td>
                      <Td>
                        <CopyButton>1.3</CopyButton>
                      </Td>
                    </tr>
                    <tr>
                      <Td>2020 - 2022</Td>
                      <Td>
                        <CopyButton>2.0</CopyButton>
                      </Td>
                    </tr>
                    <tr>
                      <Td>2023 - 2024</Td>
                      <Td>
                        <CopyButton>2.5</CopyButton>
                      </Td>
                    </tr>
                    <tr>
                      <Td>2024+</Td>
                      <Td>
                        <CopyButton>&gt; 3</CopyButton>
                      </Td>
                    </tr>
                  </tbody>
                </Table>
              </Container>
            </OverlayPanel>
          </>
        ),
      },
      "settings:environment:type": {
        requires: {},
        required_default: "direct_in_ground",
        default: "direct_in_ground",
        options: [
          "direct_in_ground",
          "direct_in_air",
          "in_j_tube",
          "in_duct",
          "in_duct_within_concrete",
          "in_unfilled_trough",
        ],
      },
      "settings:environment:duct_external_diameter": {
        requires: {
          "settings:environment:type": ["in_duct", "in_duct_within_concrete"],
        },
        default: 0,
        unit: "mm",
        min: 0,
        max: 500,
        type: "number"
      },
      "settings:environment:duct_wall_thickness": {
        requires: {
          "settings:environment:type": ["in_duct", "in_duct_within_concrete"],
        },
        default: 0,
        unit: "mm",
        min: 0,
        max: 20,
        type: "number"
      },
      "settings:environment:earthworks_trough_external_width": {
        requires: {
          "settings:environment:type": ["in_unfilled_trough"],
        },
        default: 0,
        unit: "mm",
        min: 0,
        max: 1500,
        type: "number"
      },
      "settings:environment:earthworks_trough_external_height": {
        requires: {
          "settings:environment:type": ["in_unfilled_trough"],
        },
        default: 0,
        unit: "mm",
        min: 0,
        max: 1500,
        type: "number"
      },
      "settings:environment:duct_material": {
        requires: {
          "settings:environment:type": ["in_duct", "in_duct_within_concrete"],
        },
        default: "pvc",
        options: ["pvc", "pe", "hdpe", "ldpe"],
      },
      "settings:environment:soil_drying": {
        requires: {
          "settings:environment:type": [
            "direct_in_ground",
            "in_duct",
            "in_duct_within_concrete",
          ],
        },
        default: "no_soil_drying",
        options: ["no_soil_drying", "partial_drying", "no_drying_allowed"],
      },
      "settings:environment:burial_depth": {
        requires: {
          "settings:environment:type": [
            "direct_in_ground",
            "in_duct",
            "in_duct_within_concrete",
          ],
        },
        default: 1.2,
        unit: "m",
        min: 0.1,
        max: 10,
        type: "number"
      },
      "settings:environment:ground_thermal_resistivity": {
        requires: {
          "settings:environment:type": [
            "direct_in_ground",
            "in_duct",
            "in_duct_within_concrete",
          ],
        },
        default: 0.7,
        min: 0.1,
        max: 10,
        unit: "°K-m/W",
        type: "number"
      },
      "settings:environment:ground_temperature": {
        requires: {
          "settings:environment:type": [
            "direct_in_ground",
            "in_duct",
            "in_duct_within_concrete",
          ],
        },
        default: 12,
        min: 5,
        max: 30,
        unit: "°C",
        type: "number"
      },
      "settings:environment:dry_ground_thermal_resistivity": {
        requires: {
          "settings:environment:type": [
            "direct_in_ground",
            "in_duct",
            "in_duct_within_concrete",
          ],
          "settings:environment:soil_drying": [
            "partial_drying",
            "no_drying_allowed",
          ],
        },
        default: 1.5,
        min: 0.1,
        max: 6,
        unit: "°K-m/W",
        type: "number"
      },
      "settings:environment:critical_ground_temperature": {
        requires: {
          "settings:environment:type": [
            "direct_in_ground",
            "in_duct",
            "in_duct_within_concrete",
          ],
          "settings:environment:soil_drying": [
            "partial_drying",
            "no_drying_allowed",
          ],
        },
        default: 40,
        min: 20,
        max: 60,
        unit: "°C",
        type: "number"
      },
      "settings:environment:air_temperature": {
        requires: {
          "settings:environment:type": [
            "direct_in_air",
            "in_j_tube",
            "in_unfilled_trough",
          ],
        },
        unit: "°C",
        default: 20,
        min: 5,
        max: 50,
        type: "number"
      },
      "settings:environment:thermal_conditions": {
        requires: {
          "settings:environment:type": ["direct_in_air", "in_j_tube"],
        },
        default: "solar_radiation",
        options: ["solar_radiation", "no_solar_radiation"],
      },
      "settings:cable:system_frequency": {
        requires: {},
        default: 50,
        unit: "hz",
        options: [50, 60],
      },
      "settings:cable:conductor_temperature": {
        requires: {},
        default: 90,
        unit: "°C",
        min: 20,
        max: 105,
        type: "number"
      },
      "settings:cable:maximum_metallic_screen_temperature": {
        requires: {},
        default: 80,
        unit: "°C",
        min: 20,
        max: 105,
        type: "number"
      },
      "settings:cable:number_of_optical_fibres": {
        requires: {},
        default: 48,
        unit: "qty",
        min: 0,
        max: 100,
        type: "number"
      },
      "settings:cable:single_core_cable_arrangement": {
        requires: {},
        default: "trefoil",
        options: ["trefoil", "flat"],
      },
      "settings:cable:single_core_cable_bonding_type": {
        requires: {},
        default: "cross_bonded",
        options: ["cross_bonded", "both_ends"],
      },
      "settings:cable:single_core_flat_formation_spacing": {
        requires: {
          "settings:cable:single_core_cable_arrangement": ["flat"],
        },
        default: "70+D",
        options: ["D", "70+D", "250", "300", "350", "400"],
      },
      "settings:cable:single_core_trefoil_formation_duct_option": {
        requires: {},
        options: ["duct_per_single_cable", "duct_per_three_cables"],
        default: "duct_per_single_cable",
      },
    };
    this.prefixes = ["metadata", "design", "settings"];
  }

  get_next_steps(bp_partial) {
    const next_steps = {};
    Object.keys(this.bp_graph).map((key) => {
      const all_requirements = this.bp_graph[key].requires;
      // console.log(all_requirements)
      if (Object.keys(all_requirements).length === 0) {
        next_steps[key] = { ...this.bp_graph[key], required: true, new: false };
      } else {
        let includes_all = true;
        Object.keys(all_requirements).map((param) => {
          const requirements = all_requirements[param];
          // console.log(param, requirements)
          if (bp_partial && bp_partial[param]) {
            const current_value = bp_partial[param];
            if (!requirements.includes(current_value)) {
              // console.log("included")
              includes_all = false;
            }
          } else {
            includes_all = false;
          }
        });
        if (includes_all) {
          next_steps[key] = {
            ...this.bp_graph[key],
            required: false,
            new: true,
          };
        }
      }
    });

    console.log({ next_steps });
    return next_steps;
  }

  fill_blueprint(bp_partial) {
    const bp = { ...bp_partial };
    const bp_graph = { ...this.bp_graph };
    const resolved = {};

    const resolve = (target_key) => {
      // bp = {...bp, ...bp_partial}
      // skip if already resolved
      if (resolved[target_key]) {
        // console.log("already resolved:", target_key, " skipping")
        return true;
      }

      // handle missing target_key
      if (!Object.keys(bp_graph).includes(target_key)) {
        bp[target_key] = "TARGET DOES NOT EXIST";
        resolved[target_key] = false;
        return;
      }

      const requirements = bp_graph[target_key].requires;
      const requirement_keys = Object.keys(requirements);

      // resolve if no requirements or if already in bp
      if (requirement_keys.length === 0 && bp[target_key]) {
        // console.log("already resolved: ", target_key, bp[target_key])
        resolved[target_key] = true;
        return true;
      }

      // console.log("trying to resolve:", target_key)

      let requirements_resolved = true;

      // check if sub requirements are resolved
      requirement_keys.map((req_key) => {
        if (!resolved[req_key]) {
          requirements_resolved = false;
          resolve(req_key);
        }
      });

      if (requirements_resolved) {
        resolved[target_key] = true;

        let all_requirements_met = true;
        let unaccepted_value;
        let missing_key;
        requirement_keys.map((req_key) => {
          const acceptable = requirements[req_key]; // list
          const bp_val = bp[req_key];

          if (!acceptable.includes(bp_val)) {
            // console.log(`at ${target_key}... ${bp_val} missing from ${req_key}`)
            all_requirements_met = false;
            unaccepted_value = `${req_key} == ${bp_val}`;
            missing_key = req_key;
            if (bp[target_key]) {
              bp[target_key] = acceptable;
            }
          }
        });

        if (all_requirements_met) {
          // console.log(`All requirements met ${target_key}`)
          const default_value = bp_graph[target_key].default;
          if (!bp[target_key]) {
            bp[target_key] = default_value;
          }
        } else {
          if (bp[target_key]) {
            const flag = `FLAGGED, IMPOSSIBLE!! ${target_key} cannot exist with ${unaccepted_value}`;

            if (!bp_partial[missing_key]) {
              // console.log(`bp_partial does NOT contain: ${missing_key} and can be populated`)
              bp[missing_key] = bp[target_key][0];
              resolved[target_key] = false;
              // bp_partial[missing_key] = requirements[missing_key][0]
              // resolve(target_key)
              // console.log(missing_key, "->", bp[target_key][0])
              delete bp[target_key];
              resolve(target_key);
            } else {
              // console.log(`bp_partial contains ${missing_key}`)
              bp[target_key] = flag;
            }
          }
        }
      }
    };

    for (const graph_key in bp_graph) {
      resolve(graph_key);
    }

    // return bp
    const undefined_template = {};
    for (const key in this.bp_graph) {
      undefined_template[key] = "undefined";
    }

    console.log({ ...undefined_template, ...bp });

    return { ...undefined_template, ...bp };
  }
}

// const partial_blueprint = {
//     "metadata:name": "hello",
//     // "metadata:system_voltage":"0",
//     // "conductor:area_calculation_method":"use_number_of_strands",
//     // 'conductor:strand_diameter': 10,
//     // "design:shielding": "without_shielding",
//     "design:cable_type": "single_core_armoured",
//     // "settings:environment:critical_ground_temperature": 2,
//     "filler:density": 1,
//     // "metallic_screen:type": "sheath",
//     // "conductor:area": 200,
//     // "design:shielding": "without_shielding",
//     // "conductor_shield:material": "xlpe",
//     // "metallic_screen:strand_diameter": 20,
// }

// const bp_graph = new BpGraph()

// const bp_filled = bp_graph.fill_blueprint(partial_blueprint)
// console.log(bp_filled)